/**
 * Every time this is called do:
 * 1. Check for cookie
 * 2. Call API and check for valid token
 * 3. Update token */

const fakeAuth = {
  isAuthenticated: false,

  authenticate(cb) {
    this.isAuthenticated = true;
    setTimeout(cb, 100);
  },
  signout(cb) {
    this.isAuthenticated = false;
    setTimeout(cb, 100);
  },
};

module.exports.fakeAuth = fakeAuth;
