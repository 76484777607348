import React, { Component } from 'react';
import Scrollspy from 'react-scrollspy';
import { NavHashLink } from 'react-router-hash-link';

import styles from '../../styles/modules/navigation.module.scss';

class Navigation extends Component {
  state = {};

  closeNav = () => {};

  render() {
    return (
      <Scrollspy
        items={['home', 'projects', 'about_us', 'faq', 'contact']}
        currentClassName={styles.active}
        className={styles.links}
        offset={-250}>
        <NavHashLink smooth className={styles.link} to={'#home'} onClick={() => this.closeNav()}>
          home
        </NavHashLink>
        <NavHashLink smooth className={styles.link} to={'#projects'} onClick={() => this.closeNav()}>
          projects
        </NavHashLink>
        <NavHashLink smooth className={styles.link} to={'#about_us'} onClick={() => this.closeNav()}>
          about_us
        </NavHashLink>
        <NavHashLink smooth className={styles.link} to={'#faq'} onClick={() => this.closeNav()}>
          faq
        </NavHashLink>
        <NavHashLink smooth className={styles.link} to={'#contact'} onClick={() => this.closeNav()}>
          contact
        </NavHashLink>
      </Scrollspy>
    );
  }
}

export default Navigation;
