import React, { Component } from 'react';
import axios from 'axios';

class Home extends Component {
  state = {};

  connect = () => {
    const url = 'https://lnt-database.herokuapp.com/api/user/login';
    const config = { withCredentials: true };
    const data = {
      email: 'info@linesntones.com',
      password: 'aaabbb',
    };

    axios
      .post(url, data, config)
      .then(function (response) {
        // handle success
        console.log(response);
        console.log(response.data.token);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        console.log('Finally');
      });
  };

  check = () => {
    const url = 'https://lnt-database.herokuapp.com/api/user/header';
    const config = { withCredentials: true };

    axios
      .get(url, config)
      .then(function (response) {
        // handle success
        console.log(response);
      })
      .catch(function (error) {
        // handle error
        console.log(error);
      })
      .finally(function () {
        // always executed
        console.log('Finally');
      });
  };

  render() {
    return (
      <div>
        <button onClick={this.connect}>Connect</button>
        <button onClick={this.check}>Check</button>
      </div>
    );
  }
}

export default Home;
