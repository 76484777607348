import React from 'react';
import { withRouter, Route, BrowserRouter, Switch, Redirect } from 'react-router-dom';
import E404 from './components/errors/e404';
import Home from './components/top/home';
import Login from './components/top/login';
import { fakeAuth } from './services/fakeAuth';
import Navigation from './components/common/navigation';

const Public = () => <h3>Public</h3>;
const Protected = () => <h3>Protected</h3>;

const PrivateRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={(props) =>
      fakeAuth.isAuthenticated === true ? (
        <Component {...props} />
      ) : (
        <Redirect
          to={{
            pathname: '/login',
            state: { from: props.location },
          }}
        />
      )
    }
  />
);

const AuthButton = withRouter(({ history }) =>
  fakeAuth.isAuthenticated ? (
    <p>
      Welcome!{' '}
      <button
        onClick={() => {
          fakeAuth.signout(() => history.push('/'));
        }}>
        Sign out
      </button>
    </p>
  ) : (
    <p>You are not logged in.</p>
  )
);

function App() {
  return (
    <BrowserRouter>
      <Navigation />
      <AuthButton />
      <Switch>
        <Route exact path='/page-not-found' render={(props) => <E404 {...props} />} />

        <Route exact path='/' render={(props) => <Home {...props} />} />
        {/* <Route path='/login' render={(props) => <Login {...props} />} />

        <PrivateRoute path='/protected' component={Protected} /> */}

        <Redirect to='/page-not-found' />
      </Switch>
    </BrowserRouter>
  );
}

export default withRouter(App);
